const locationTeams = {
  cluster1: [
    "Medewerkers Bergeijk - Noord",
    "Medewerkers Bergeijk - Zuid",
    "Medewerkers Team Aalst",
    "Medewerkers Team Valkenswaard Oost",
    "Medewerkers Team Valkenswaard West",
    "Medewerkers Team Waalre",
  ],
  cluster1zgt: [
    "Medewerkers Team Bergeijk Gewoon Thuis",
    "Medewerkers Team Valkenswaard Gewoon Thuis",
    "Medewerkers Team Waalre Gewoon Thuis",
  ],
  cluster2: ["Waalre", "Medewerkers Team ZTB West"],
};

export default locationTeams;
