import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { googleApps } from "./Apps/GoogleApps";
import { wijkzorgApps } from "./Apps/WijkzorgApps";
import { tcApps } from "./Apps/ThuiscoachingApps";
import { vastgoedApps } from "./Apps/VastgoedApps";
import { additionalApps } from "./Apps/AdditionalApps";
import "./home.css";

  const all_thuiscoaching_access = JSON.parse(
    process.env.REACT_APP_ALL_THUISCOACHING
  );
  const all_wijkzorg_access = JSON.parse(process.env.REACT_APP_ALL_WIJKZORG);
  const all_vastgoed_access = JSON.parse(process.env.REACT_APP_ALL_VASTGOED);

const callCloudFunction = async (userEmail) => {
  try {
    const userData = await fetch(process.env.REACT_APP_FETCH_USER_DETAILS, {
      method: "POST",
      body: JSON.stringify({ userKey: userEmail, projection: "full" }),
    });
    const googleUser = await userData.json();
    return googleUser.user;
  } catch (error) {
    console.error("Error calling cloud function:", error);
    throw error;
  }
};

const Tiles = () => {
  const navigate = useNavigate();
  const { isAuthenticated, login, updateUserData, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [department, setDepartment] = useState("");
  const [userFunction, setUserFunction] = useState("");
  const [userOu, setUserOu] = useState("");

  useEffect(() => {
    const storedUser = localStorage.getItem("isAuthenticated");
    const storedUserDetails = localStorage.getItem("userdetails");

    if (storedUser && !isAuthenticated) {
      const user = JSON.parse(storedUser);
      login(user);

      // Update user details using 'storedUserDetails' if available
      const userDetails = storedUserDetails
        ? JSON.parse(storedUserDetails)
        : null;
      updateUserData(userDetails || user);
    }

    if (userData) {
      const userOu = userData.orgUnitPath;
      setUserOu(userOu);
      const userDepartment = getDepartment(userData);
      setDepartment(userDepartment);
      const userFunction = getUserFunction(userData);
      setUserFunction(userFunction);
    }
  }, [login, updateUserData, isAuthenticated, userData, forceUpdate]);

  const handleLoginSuccess = async (credentialResponse) => {
    setLoading(true);

    try {
      const credentialResponseDecoded = jwtDecode(
        credentialResponse.credential
      );
      const userEmail = credentialResponseDecoded.email;
      const additionalUserData = await callCloudFunction(userEmail);
      const updatedUserData = {
        ...credentialResponseDecoded,
        ...additionalUserData,
      };

      login(updatedUserData);

      localStorage.setItem("isAuthenticated", JSON.stringify(updatedUserData));
      localStorage.setItem("userdetails", JSON.stringify(updatedUserData));
      reCheckUserDetails(userEmail, setForceUpdate);
    } catch (error) {
      console.error("Error fetching additional user data:", error);
    }

    setLoading(false);

    navigate("/");
  };

  const reCheckUserDetails = (userEmail, setForceUpdate) => {
    const fetchAndSetUserDetails = async () => {
      try {
        const refreshedUserData = await callCloudFunction(userEmail);
        updateUserData(refreshedUserData);
        setForceUpdate((prev) => !prev);
      } catch (error) {
        console.error("Error refreshing user details:", error);
      }
    };

    clearInterval(reCheckUserDetails.intervalId);
    reCheckUserDetails.intervalId = setInterval(
      fetchAndSetUserDetails,
      3600000
    );
    return reCheckUserDetails.intervalId;
  };

  useEffect(() => {}, [forceUpdate]);

  const getDepartment = (userData) => {
    if (
      userData &&
      userData.organizations &&
      userData.organizations.length > 0
    ) {
      return userData.organizations[0].department;
    }
    return "";
  };

  const getUserFunction = (userData) => {
    if (
      userData &&
      userData.organizations &&
      userData.organizations.length > 0
    ) {
      return userData.organizations[0].title;
    }
    return "";
  };

  const LoadingSpinner = () => (
    <div className="content-loading">
      <h2 className="pagename">Tegels laden..</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <div
          style={{
            border: "4px solid #013e74",
            borderRadius: "50%",
            borderTop: "4px solid #afca09",
            width: "60px",
            height: "60px",
            animation: "spin 0.9s linear infinite",
          }}
        ></div>
      </div>
    </div>
  );

  const renderApps = (
    apps,
    userData,
    userOu,
    allAccess,
    department,
    userFunction
  ) => {
    return apps
      .filter((app) =>
        app.condition(userData, userOu, allAccess, department, userFunction)
      )
      .map((app, index) => (
        <div className="grid-element" key={index}>
          <a href={app.url} target="_blank" rel="noopener noreferrer">
            <img src={app.image} alt={app.name} />
            {app.name}
          </a>
        </div>
      ));
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
      <div>
        <hr className="border"></hr>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {isAuthenticated ? (
              <div className="content">
                <h2 className="pagename">Google Apps</h2>
                <div className="grid-container grid-container--fill">
                  {renderApps(googleApps, userData)}
                </div>
                <h2 className="pagename">
                  {userOu.includes("/Wijkzorg")
                    ? "Wijkzorg Apps"
                    : userOu.includes("/TC")
                    ? "Thuiscoaching Apps"
                    : userOu.includes("/Vastgoed")
                    ? "Vastgoed Apps"
                    : userOu.includes("/ZSC")
                    ? "Stichting Apps"
                    : "Geen Apps Beschikbaar"}
                </h2>
                <div className="grid-container grid-container--fill">
                  {renderApps(
                    wijkzorgApps,
                    userOu,
                    all_wijkzorg_access,
                    department
                  )}
                  {renderApps(
                    tcApps,
                    userOu,
                    all_thuiscoaching_access,
                    department
                  )}
                  {renderApps(
                    vastgoedApps,
                    userOu,
                    all_vastgoed_access,
                    department
                  )}
                  {renderApps(
                    additionalApps,
                    userData,
                    userFunction,
                    department
                  )}
                </div>
              </div>
            ) : (
              <div className="content">
                <h2 className="pagename">Inloggen met Google</h2>
                <div className="loginbutton">
                  <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    cookiePolicy={"single_host_origin"}
                    type="standard"
                    size="medium"
                    shape="circle"
                    width="200px"
                    ux_mode="popup"
                    theme="filled_blue"
                    text="continue_with"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </GoogleOAuthProvider>
  );
};

export default Tiles;
